import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'

import Container from './layout/container'
import { BREAKPOINTS } from '../styles/cssVariables'

const ImageTextWrapper = styled.section`
  position: relative;
  padding: 70px 0;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    padding: 35px 0;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding: 45px 0 15px;
  }
`

const StyledContainer = styled(Container)`
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    > div {
      flex-direction: column-reverse;
    }
  }
`

const TextPanel = styled.div`
  flex: 1;

  @media (min-width: ${BREAKPOINTS.smlUp}px) {
    ${(props) => props.imagePosition === 'left' && 'order: 1;'}

    > * {
      ${(props) =>
        props.imagePosition === 'right'
          ? 'margin-right: auto;'
          : 'margin-left: auto;'}

      width: calc(5 / 6 * 100%);
    }
  }
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    > * {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    }
  }
`

const Heading = styled.h2`
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    text-align: center;
  }
`

const Body = styled.p`
  margin-top: 35px;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    margin-bottom: 35px;
    text-align: center;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin: 30px 0 0;
  }
`

const ImagePanel = styled.div`
  flex: 1;

  border-radius: 4px;

  filter: drop-shadow(0px 10px 20px rgba(15, 24, 44, 0.1));
  overflow: hidden;

  @media (max-width: ${BREAKPOINTS.medUp}px) {
    width: 100%;
    margin-bottom: 40px;
    &:last-child {
      order: 1;
    }
  }
`

/**
 * Displays a styled block of text next to an image.
 * Examples on About page.
 */
const TextImage = (props) => {
  return (
    <ImageTextWrapper>
      <StyledContainer width={12} flex={true}>
        <TextPanel imagePosition={props.imagePosition}>
          <Heading>{props.heading}</Heading>
          <Body>{props.body}</Body>
        </TextPanel>
        <ImagePanel imagePosition={props.imagePosition}>
          <GatsbyImage
            alt={props.image.title}
            image={props.image.gatsbyImageData}
          />
        </ImagePanel>
      </StyledContainer>
    </ImageTextWrapper>
  )
}

TextImage.propTypes = {
  /** Body text */
  body: PropTypes.string,
  /** Heading text */
  heading: PropTypes.string,
  /** Image displayed alongside text */
  image: PropTypes.shape({
    title: PropTypes.string,
    gatsbyImageData: PropTypes.object,
  }),
  /** Specifies which side the image displays, on desktop devices */
  imagePosition: PropTypes.oneOf(['left', 'right']).isRequired,
}

TextImage.defaultProps = {
  imagePosition: 'right',
}

export default TextImage
