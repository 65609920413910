import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import MediaQuery from 'react-responsive'
import IconLinkArrowSvg from '../images/icons/link-arrow.svg'
import Layout from '../components/layout/layout'
import Container from '../components/layout/container'
import { BREAKPOINTS, LAYOUT, ANIMATION } from '../styles/cssVariables'
import TextImage from '../components/textImage'
import InfoCard from '../components/ui-kit/infoCard'
import FeatureList from '../components/featureList'
import MarqueeText from '../components/marqueeText'
import LogoGrid from '../components/logoGrid'
import SEO from '../components/layout/seo'
import slugify from 'slugify'
import { isAbsoluteUrl } from '../utils/utils'
import Hero from '../components/hero'


const FeaturesSection = styled.section`

`
const FeaturesContainer = styled(Container)`
  margin-bottom: 200px;
  @media (max-width: ${BREAKPOINTS.med}px) {
    margin-bottom: 175px;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-bottom: 150px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-bottom: 100px;
  }
  padding-top: 150px;

  @media (max-width: ${BREAKPOINTS.med}px) {
    padding-top: 100px;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    padding-top: 75px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    // padding-top: 100px;
  }
`

const FeaturesHeading = styled.h2`
  margin-bottom: 40px;
  text-align: ${(props) => props.textAlign.toLowerCase()};
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    width: 100%;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-bottom: 30px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-bottom: 20px;
  }
`

const FeaturesCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-gap: 22px 30px;

  @media (max-width: ${BREAKPOINTS.med}px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    grid-template-columns: 1fr;
  }
`

const FeaturesBody = styled.p`
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  margin-bottom: 50px;
  text-align: center;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    width: 100%;
  }
`

const InfoCardCentred = styled(InfoCard)`
  flex: 1;

  @media (min-width: ${BREAKPOINTS.smlUp}px) {
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    > span {
      transform-origin: center;
    }
  }
`

const FeatureListWrapper = styled.section`
  margin-top: 90px;
  margin-bottom: 120px;
`

const LogoListWrapper = styled.section`
  padding-bottom: 250px;
  padding-top: 50px;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    padding-top: 0;
    padding-bottom: 200px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding: 0 0 calc(150px - 38px);
  }
`
const LogoListContainer = styled.div`
  padding: 0 ${LAYOUT.gutterPage}px;
  box-sizing: border-box;
  width: 100%;
  max-width: ${LAYOUT.pageWidth}px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    padding: 0 ${LAYOUT.gutterPage / 2}px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding: 0 ${LAYOUT.gutterPageMobile}px;
  }
`
const CommunityCta = styled.a`
  display: block;
  padding: 60px 0 75px;
  font-size: 28px;
  text-align: center;
  line-height: 40px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--color-violet);
  -webkit-text-fill-color: transparent;
`
const FeatureListTitle = styled.h2`
  margin-bottom: 42px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    width: 100%;
  }
`

const InfoGridButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    @media (max-width: ${BREAKPOINTS.tbl}px) {
      flex-direction: column;
      align-items: center;
    }
`
const InfoGridButton = styled.a`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 1.25em 2.25em;
  border: 1px solid var(--color-skobeloff);

  border-radius: 4px;
  margin-top: 60px;
  background-color: var(--color-skobeloff);
  color:  var(--color-white) !important;
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: transparent;
    color:  var(--color-skobeloff) !important;
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    margin-top: 40px;

  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-top: 25px;

  }
`

const InfoGridButtonGatsbyLink = styled(Link)`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 1.25em 2.25em;
  border: 1px solid var(--color-skobeloff);
  background-color: var(--color-skobeloff);
  color:  var(--color-white) !important;
  border-radius: 4px;
  margin-top: 60px;
 
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: transparent;
    color:  var(--color-skobeloff) !important;
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    margin-top: 40px;

  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-top: 25px;

  }
`

const InfoGridSecondButton = styled.a`
  box-sizing: border-box;  
  width: fit-content;
  position: relative;

  display: flex;
  align-items: center;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 60px;
  margin-left: 20px;
  background-color: #fff;
  border: 1px solid;
  color: var(--color-skobeloff);
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;


  &:hover {
    color: var(--color-blue) !important;
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    margin-top: 40px;

  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-left: 0;

    border: 1px solid;

  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-top: 25px;

  }
`
const InfoGridButtonSecondGatsbyLink = styled(Link)`
  box-sizing: border-box;  
  width: fit-content;
  position: relative;

  display: flex;
  align-items: center;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 60px;
  margin-left: 20px;
  background-color: #fff;
  border: 1px solid;
  color: var(--color-skobeloff);
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;


  &:hover {
    color: var(--color-blue) !important;
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    margin-top: 40px;

  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-left: 0;

    border: 1px solid;

  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-top: 25px;

  }
`
const Arrow = styled(IconLinkArrowSvg)`
  margin-left: 0.4em;
`

class AboutPage extends React.Component {
  render() {
    const openGraphImage = get(this, 'props.data.contentfulPageAbout.openGraphImage')

    const heroHeading = get(this, 'props.data.contentfulPageAbout.hero.heading')
    const heroSubheading = get(
      this,
      'props.data.contentfulPageAbout.hero.subheading'
    )
    const heroCtaText = get(this, 'props.data.contentfulPageAbout.hero.ctaText')
    const heroCtaDestination = get(
      this,
      'props.data.contentfulPageAbout.hero.ctaDestination'
    )

    const textImage1 = get(this, 'props.data.contentfulPageAbout.textimage1')
    const textImage2 = get(this, 'props.data.contentfulPageAbout.textimage2')

    const ourPillarsTitle = get(
      this,
      'props.data.contentfulPageAbout.ourPillarsGrid.title'
    )
    const ourPillarsTitleAlignment = get(
      this,
      'props.data.contentfulPageAbout.ourPillarsGrid.titleAlignment'
    )
    const ourPillarsColumns = get(
      this,
      'props.data.contentfulPageAbout.ourPillarsGrid.columns'
    )
    const ourPillars = get(
      this,
      'props.data.contentfulPageAbout.ourPillarsGrid.infoCards'
    )
    const ourPillarsCtaText = get(
      this,
      'props.data.contentfulPageAbout.ourPillarsGrid.ctaText'
    )
    const ourPillarsCtaDestination = get(
      this,
      'props.data.contentfulPageAbout.ourPillarsGrid.ctaDestination'
    )
    const ourPillarsSecondCtaText = get(
      this,
      'props.data.contentfulPageAbout.ourPillarsGrid.secondCtaText'
    )
    const ourPillarsSecondCtaDestination = get(
      this,
      'props.data.contentfulPageAbout.ourPillarsGrid.secondCtaDestination'
    )

    const ourPillarsBodyText = get(
      this,
      'props.data.contentfulPageAbout.ourPillarsGrid.body.body'
    )


    const featureListLayout = get(
      this,
      'props.data.contentfulPageAbout.howDoesItWork.layout'
    )
    const featureListHeading = get(
      this,
      'props.data.contentfulPageAbout.howDoesItWork.title'
    )
    const featureListImage = get(
      this,
      'props.data.contentfulPageAbout.howDoesItWork.image'
    )
    const featureListCards = get(
      this,
      'props.data.contentfulPageAbout.howDoesItWork.featureCards'
    )
    const featureListCtaText = get(
      this,
      'props.data.contentfulPageAbout.howDoesItWork.ctaText'
    )
    const featureListCtaDestination = get(
      this,
      'props.data.contentfulPageAbout.howDoesItWork.ctaDestination'
    )

    const textImage3 = get(this, 'props.data.contentfulPageAbout.textimage3')

    const marqueeText = get(this, 'props.data.contentfulPageAbout.marquee.text')
    const marqueeSeparatorCharacter = get(
      this,
      'props.data.contentfulPageAbout.marquee.separatorCharacter'
    )
    const marqueeLinkDestination = get(
      this,
      'props.data.contentfulPageAbout.marquee.linkDestination'
    )

    const partners = get(this, 'props.data.contentfulSite.supporters.logos')
    const partnersTitle = get(
      this,
      'props.data.contentfulSite.supporters.title'
    )

    const partnersCtaText = get(
      this,
      'props.data.contentfulSite.supporters.ctaText'
    )
    const partnersCtaDestination = get(
      this,
      'props.data.contentfulSite.supporters.ctaDestination'
    )

    const seoTitle = get(this, 'props.data.contentfulPageAbout.seoTitle')
    const seoDescription = get(this, 'props.data.contentfulPageAbout.seoDescription')


    return (
      <Layout location={this.props.location}>
        <SEO
          pageTitle={seoTitle}
          seoDescription={seoDescription}
          // ogImage={openGraphImage.gatsbyImageData.images.fallback.src}
        />

      <Hero 
        id="mission"
        heading={heroHeading}
        subheading={heroSubheading}
        ctaText={heroCtaText}
        ctaDestination={heroCtaDestination}
      />

        <TextImage
          body={textImage1.body.body}
          heading={textImage1.heading}
          image={textImage1.image}
          imagePosition={textImage1.imagePosition.toLowerCase()}
        />
        <TextImage
          body={textImage2.body.body}
          heading={textImage2.heading}
          image={textImage2.image}
          imagePosition={textImage2.imagePosition.toLowerCase()}
        />
        <FeaturesSection>
          <FeaturesContainer width={12}>
            <FeaturesHeading textAlign={ourPillarsTitleAlignment}>
              {ourPillarsTitle}
            </FeaturesHeading>
            <FeaturesBody>
              {ourPillarsBodyText}
            </FeaturesBody>
            <FeaturesCardsContainer columns={ourPillarsColumns}>
              {ourPillars.map((infoCard) => (
                <InfoCardCentred
                  key={slugify(infoCard.heading)}
                  heading={infoCard.heading}
                  icon={infoCard.icon}
                  layout={infoCard.layout.toLowerCase()}
                  body={infoCard.body.body}
                />
              ))}
            </FeaturesCardsContainer>
            <InfoGridButtonContainer>
              {ourPillarsCtaText && (
              <>
                {isAbsoluteUrl(ourPillarsCtaDestination) ? (
                  <InfoGridButton  href={ourPillarsCtaDestination}
                  target="__blank"
                  rel="nofollow">
                  
                    {ourPillarsCtaText}
                    <Arrow />
                  </InfoGridButton>
                ) : (
                  <InfoGridButtonGatsbyLink to={ourPillarsCtaDestination}>
                    {ourPillarsCtaText}
                    <Arrow />
                  </InfoGridButtonGatsbyLink>
                )}
              
              </>
    
          
            )}
            {ourPillarsSecondCtaText && (
              <>
                {isAbsoluteUrl(ourPillarsSecondCtaDestination) ? (
                  <InfoGridSecondButton  href={ourPillarsSecondCtaDestination}
                  target="__blank"
                  rel="nofollow">
                  
                    {ourPillarsSecondCtaText}
                  
                  </InfoGridSecondButton>
                ) : (
                  <InfoGridButtonSecondGatsbyLink to={ourPillarsSecondCtaDestination}>
                    {ourPillarsSecondCtaText}
                    
                  </InfoGridButtonSecondGatsbyLink>
                )}
              
              </>
    
          
            )}
          </InfoGridButtonContainer>
          </FeaturesContainer>
        </FeaturesSection>
        <FeatureListWrapper id="how-it-works">
          <Container width={12}>
          <FeatureListTitle>
              {featureListHeading}
          </FeatureListTitle>
            <FeatureList
              accentColor="var(--color-violet)"
              ctaText={featureListCtaText}
              ctaDestination={featureListCtaDestination}
              featureCardArr={featureListCards.map((card) => [
                card.heading,
                card.body.body,
              ])}
              image={featureListImage}
              layout={featureListLayout}
              offsetImage={true}
            />
          </Container>
        </FeatureListWrapper>

        <TextImage
          body={textImage3.body.body}
          heading={textImage3.heading}
          image={textImage3.image}
          imagePosition={textImage3.imagePosition.toLowerCase()}
        />

        <MediaQuery minWidth={769}>
          <MarqueeText wordArray={['Join the MindfulCommerce community']} />
        </MediaQuery>
        <MediaQuery maxWidth={768}>
          <Container width={12}>
            <CommunityCta
              href={marqueeLinkDestination}
              rel="noreferrer"
              target="_blank"
            >
              {marqueeText} {marqueeSeparatorCharacter}
            </CommunityCta>
          </Container>
        </MediaQuery>

        <LogoListWrapper>
          <LogoListContainer>
            <LogoGrid
              ctaDestination={partnersCtaDestination}
              ctaText={partnersCtaText}
              logos={partners}
              title={partnersTitle}
            />
          </LogoListContainer>
        </LogoListWrapper>
      </Layout>
    )
  }
}

export default AboutPage

export const aboutQuery = graphql`
  query AboutQuery {
    contentfulPageAbout {
      seoTitle
      seoDescription
      openGraphImage {
        title
        gatsbyImageData(layout: FULL_WIDTH, quality: 85)
      }
      hero {
        heading
        subheading
        ctaText
        ctaDestination
        
      }
      textimage1 {
        heading
        body {
          body
        }
        image {
          title
          gatsbyImageData(layout: FULL_WIDTH, quality: 85)
        }
        imagePosition
      }
      textimage2 {
        heading
        body {
          body
        }
        image {
          title
          gatsbyImageData(layout: FULL_WIDTH, quality: 85)
        }
        imagePosition
      }
      ourPillarsGrid {
        title
        titleAlignment
        columns
        infoCards {
          icon {
            file {
              url
            }
          }
          heading
          body {
            body
          }
          ctaText
          ctaDestination
          layout
        }
        ctaText
        ctaDestination
        secondCtaText
        secondCtaDestination
        body {
          body
        }
      }
      howDoesItWork {
        layout
        image {
          title
          gatsbyImageData(layout: FULL_WIDTH)
        }
        title
        featureCards {
          heading
          body {
            body
          }
        }
        ctaText
        ctaDestination
      }
      textimage3 {
        heading
        body {
          body
        }
        image {
          title
          gatsbyImageData(layout: FULL_WIDTH, quality: 85)
        }
        imagePosition
      }
      marquee {
        text
        separatorCharacter
        linkDestination
      }
     
    }
    contentfulSite {
      supporters {
        title
        logos {
          id
          title
          gatsbyImageData(layout: FULL_WIDTH)
          description
        }
        ctaText
        ctaDestination
      }
      
    } 
  }
`
